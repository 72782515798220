// Contact.js
import React, { useState, useRef } from 'react';
import emailjs from 'emailjs-com';
import {
  Container,
  FormWrap,
  Icon,
  FormContent,
  Form,
  FormH1,
  FormLabel,
  FormInput,
  FormTextarea,
  FormButton,
  Text
} from './ContactElements';

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [formError, setFormError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const formRef = useRef();

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!name || !email || !phone || !message) {
      setFormError('Veuillez remplir tous les champs.');
    } else {
      emailjs
        .sendForm(
          'service_awvnbo7',
          'template_jct5tgv',
          formRef.current,
          'VwYE82M1vRND5P9hF'
        )
        .then(
          (result) => {
            console.log('Le message a été envoyé.', result.text);
            setSuccessMessage(
              'Merci pour votre message. Nous y répondrons bientôt.'
            );
            setErrorMessage('');
          },
          (error) => {
            console.log('Une erreur s\'est produite.', error.text);
            setErrorMessage(
              'Nous rencontrons actuellement des problèmes techniques. Votre message n\'a pas pu être envoyé. Veuillez réessayer ultérieurement.'
            );
            setSuccessMessage('');
          }
        );
      setFormError('');
    }
  };

  return (
    <>
      <Container>
        <FormWrap>
          <Icon to="/">Bio Services Partner</Icon>
          <FormContent>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <FormH1>Contactez-nous</FormH1>
              <FormLabel htmlFor='from_name'>Nom</FormLabel>
              <FormInput
                type='text'
                id='from_name'
                name='from_name'
                value={name}
                onChange={(event) => setName(event.target.value)}
                required
              />
              <FormLabel htmlFor='from_email'>Email</FormLabel>
              <FormInput
                type='email'
                id='from_email'
                name='from_email'
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                required
              />
              <FormLabel htmlFor='phone'>Numéro de téléphone</FormLabel>
              <FormInput
                type='tel'
                id='phone'
                name='phone'
                value={phone}
                onChange={(event) => setPhone(event.target.value)}
                required
              />
              <FormLabel htmlFor='message'>Message</FormLabel>
              <FormTextarea
                id='message'
                name='message'
                value={message}
                onChange={(event) => setMessage(event.target.value)}
                required
              />
              {formError && <Text>{formError}</Text>}
              {successMessage && <Text>{successMessage}</Text>}
              {errorMessage && <Text>{errorMessage}</Text>}
              <FormButton type='submit'>Envoyer</FormButton>
            </Form>
          </FormContent>
        </FormWrap>
      </Container>
    </>
  );
};

export default Contact;