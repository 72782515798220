export const homeObjOne = {
  /*
  Undraw : "collaboration", "cleaning", "environment", "partnership"
  The Noun Project : "collaboration", "cleaning", "environment", "partnership", "teamwork", "sustainability"
  SVG Repo : "collaboration", "cleaning", "environment", "partnership", "teamwork", "sustainability"
  */
  id: 'about',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Bio Services Partner',
  headline: 'Plus de 10 ans d\'expérience en nettoyage professionnel',
  description: 'Bio Services Partner est votre partenaire privilégié pour des services de nettoyage de qualité, respectueux de l\'environnement et socialement responsables. Nous travaillons en étroite collaboration avec nos clients pour répondre à leurs besoins spécifiques et leur offrir des solutions sur mesure.',
  buttonLabel: 'En savoir plus',
  href: 'services',
  imgStart: false,
  img: require('../../images/svg-handshake.svg').default,
  alt: 'Car',
  dark: true,
  primary: true,
  darkText: false
};

export const homeObjTwo = {
  /*
  Undraw : "collaboration", "cleaning", "environment", "partnership"
  The Noun Project : "collaboration", "cleaning", "environment", "partnership", "teamwork", "sustainability"
  SVG Repo : "collaboration", "cleaning", "environment", "partnership", "teamwork", "sustainability
  */
  id: 'services',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Nos services',
  headline: 'Des solutions de nettoyage professionnel sur mesure',
  description: 'Chez Bio Services Partner, nous proposons une large gamme de services de nettoyage professionnel pour répondre aux besoins de nos clients. Nous travaillons en étroite collaboration avec eux pour comprendre leurs besoins spécifiques et leur offrir des solutions sur mesure, respectueuses de l\'environnement et socialement responsables.',
  buttonLabel: 'En savoir plus',
  href: "services2",
  imgStart: true,
  img: require('../../images/svg-nature.svg').default,
  alt: 'Car',
  dark: false,
  primary: false,
  darkText: true
};

export const homeObjThree = {
  /*
  Undraw : "partnership", "collaboration", "teamwork", "business"
  The Noun Project : "partnership", "collaboration", "teamwork", "business", "clients", "sustainability"
  SVG Repo : "partnership", "collaboration", "teamwork", "business", "clients", "sustainability"
  */
  id: 'clients',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Nos clients',
  headline: 'Des partenariats durables avec des entreprises de toutes tailles',
  description: 'Chez Bio Services Partner, nous sommes fiers de travailler avec des entreprises de toutes tailles et de tous secteurs d\'activité. Nous croyons en des partenariats durables et en une collaboration étroite avec nos clients pour répondre à leurs besoins spécifiques et leur offrir des solutions sur mesure.',
  buttonLabel: 'Nous contacter',
  href: "home",
  imgStart: true,
  img: require('../../images/svg-people.svg').default,
  alt: 'Car',
  dark: false,
  primary: false,
  darkText: true
};