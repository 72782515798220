import React from 'react'
import Contact from '../components/Contact'
import ScrollToTop from '../components/ScrollToTop'


const ContactPage = () => {
  return (
    <>
      <ScrollToTop />
      <Contact />
    </>
  )
}

export default ContactPage