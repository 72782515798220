import React from 'react'
import Icon1 from '../../images/svg-building.svg'
import Icon2 from '../../images/svg-window.svg'
import Icon3 from '../../images/svg-question_mark.svg'
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP
} from './ServicesElements';

const Services = () => {
  return (
    <ServicesContainer id="services2">
      <ServicesH1>Nos Services</ServicesH1>
      <ServicesWrapper>
        <ServicesCard>
          {/* Tags : "cleaning", "office", "public space", "environment", "teamwork" */}
          <ServicesIcon src={Icon1}/>
          <ServicesH2>Nettoyage courant des bâtiments</ServicesH2>
          <ServicesP>Nettoyage professionnel pour bureaux, commerces ou autre.</ServicesP>
        </ServicesCard>
        <ServicesCard>
          {/* Tags : "cleaning", "maintenance", "floor", "window", "environment" */}
          <ServicesIcon src={Icon2}/>
          <ServicesH2>Entretien de surfaces</ServicesH2>
          <ServicesP>Entretien professionnel des sols, vitres et autres surfaces.</ServicesP>
        </ServicesCard>
        <ServicesCard>
          {/* "custom", "collaboration", "partnership", "teamwork", "business" */}
          <ServicesIcon src={Icon3}/>
          <ServicesH2>Services personnalisés</ServicesH2>
          <ServicesP>Solutions de nettoyage sur mesure pour répondre à vos besoins.</ServicesP>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  )
}

export default Services