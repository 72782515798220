import React from 'react'
import { animateScroll as scroll } from 'react-scroll';
import {
  FooterContainer,
  FooterWrap,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  WebsiteRights,
} from './FooterElements'

const Footer = () => {
  // Fonction pour revenir en haut de la page au clic sur le logo
  const toggleHome = () => {
    scroll.scrollToTop();
  }

  return (
    <FooterContainer>
      <FooterWrap>
        {/* Section des médias sociaux */}
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to="/" onClick={toggleHome}>
              Bio Services Partner
            </SocialLogo>
            <WebsiteRights>&copy; {new Date().getFullYear()} Bio Services Partner. Tous droits réservés.</WebsiteRights>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  )
}

export default Footer